import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScanPage from './ScanPage';
import QrStatistics from './QrStatistics';
import About from './About';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ScanPage />} ></Route>
        <Route path='/stats' element={<QrStatistics />} ></Route>
        <Route path='/about' element={<About />} ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
