import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const apiKeyFirebase = process.env.REACT_APP_FIREBASE_API_KEY

export const firebaseConfig = {
  apiKey: `${apiKeyFirebase}`,
  authDomain: "spotlytic.firebaseapp.com",
  projectId: "spotlytic",
  storageBucket: "spotlytic.firebasestorage.app",
  messagingSenderId: "623288049513",
  appId: "1:623288049513:web:29aec5ead382077be867f8",
  measurementId: "G-MCMEN4VKDP"
};


export const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);