import React, { useEffect, useState } from "react";
import { Table, Container, Spinner } from "react-bootstrap";
import { Card, Row, Col } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { app } from "./Firebase";
import { orderBy, Timestamp } from 'firebase/firestore';
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const QrStatistics = () => {
    const [searchParams] = useSearchParams();
    const [analyticsState, setAnalyticsState] = useState({
        loading: true,
        error: null,
        analytics: []
    })

    const groupID = searchParams.get("groupID");

    useEffect(() => {
        const fetchQrStats = async () => {
            try {
                const db = getFirestore(app);
                const queryAnalytics = query(
                    collection(db, "analytics"),
                    where("qrGroupId", "==", groupID),
                    orderBy('timestamp', 'desc')
                );
                const analyticsSnapshot = await getDocs(queryAnalytics);

                const analyticArray = analyticsSnapshot.docs.map(doc => doc.data())

                console.log("data: ", analyticArray)

                setAnalyticsState(prevState => ({
                    ...prevState,
                    loading: false,
                    analytics: analyticArray,
                    error: null
                }))
            } catch (error) {
                console.log(`fetch response ERROR: ` + error)
                setAnalyticsState(prevState => ({
                    ...prevState,
                    loading: false,
                    error: "error fetching analytics"
                }))
            }
        };
        fetchQrStats();
    }, [groupID]);

    if (analyticsState.loading) {
        return (
            <Container
                fluid
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (analyticsState.error) {
        return <MessageInTheCenter message={analyticsState.error} />
    }

    return <AnalyticsTable analyticArray={analyticsState.analytics} groupId={groupID} />;
}

const MessageInTheCenter = ({ message }) => {
    return (
        <Container
            fluid
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
        >
            <p>{message}</p>
        </Container>
    )
}

const AnalyticsTable = ({ analyticArray, groupId }) => {
    if (!analyticArray || analyticArray.length === 0) {
        return <MessageInTheCenter message={"No analytics data available for this QR group."} />
    }

    const groupedData = {};

    analyticArray.forEach((item) => {
        const { adUrl } = item;
        if (!groupedData[adUrl]) {
            groupedData[adUrl] = [];
        }
        groupedData[adUrl].push(item);
    });

    return (
        <Container>
            <h3 className="my-4">Analytics Dashboard for group: {groupId}</h3>
            {

                Object.entries(groupedData).map(([adUrl, items]) => (
                    <div key={adUrl} style={{ marginBottom: "30px" }}>
                        <div style={{ borderTop: "1px solid #ccc", margin: "20px 0" }} />
                        <h5 className="my-4">Ad URL:
                            <a href={adUrl || "#"} target="_blank" rel="noopener noreferrer">
                                {adUrl || "N/A"}
                            </a>
                        </h5>

                        <AdPerformancePieChart analyticArray={items} />
                        <AdPerformanceNumbers analyticArray={items} />
                    </div>

                ))
            }

            <div style={{ borderTop: "1px solid #ccc", margin: "20px 0" }} />

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Ad ID</th>
                        <th>Ad URL</th>
                        <th>OS</th>
                        <th>QR Unique ID</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {analyticArray.map((data, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.adId || "N/A"}</td>
                            <td>
                                <a href={data.adUrl || "#"} target="_blank" rel="noopener noreferrer">
                                    {data.adUrl || "N/A"}
                                </a>
                            </td>
                            <td>{data.os || "N/A"}</td>
                            <td>{data.qrUniqueId || "N/A"}</td>
                            <td>{data.timestamp ? new Date(data.timestamp.seconds * 1000).toLocaleString() : "N/A"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

const AdPerformanceNumbers = ({ analyticArray }) => {
    const totalImpressions = analyticArray.length;
    const totalImpressionsAndroid = analyticArray.filter(item => item.os === 'Android').length
    const totalImpressionsIOS = analyticArray.filter(item => item.os === 'iOS').length
    const totalImpressionsOther = totalImpressions - totalImpressionsAndroid - totalImpressionsIOS

    const data = [
        { title: "Total Impressions", value: totalImpressions },
        { title: "Android OS Impressions", value: totalImpressionsAndroid },
        { title: "iOS OS Impressions", value: totalImpressionsIOS },
        { title: "Other OS Impressions", value: totalImpressionsOther }
    ]

    return (
        <div>
            <Row className="my-4">
                {data.map((item, index) => (
                    <Col md={3} className="mb-3" key={index}>
                        <Card>
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text>{item.value}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const AdPerformancePieChart = ({ analyticArray }) => {
    const totalImpressions = analyticArray.length;
    const totalImpressionsAndroid = analyticArray.filter(item => item.os === 'Android').length
    const totalImpressionsIOS = analyticArray.filter(item => item.os === 'iOS').length
    const totalImpressionsOther = totalImpressions - totalImpressionsAndroid - totalImpressionsIOS

    const data = [
        { name: "Android", value: totalImpressionsAndroid, color: '#0088FE' },
        { name: "iOS", value: totalImpressionsIOS, color: '#00C49F' },
        { name: "Other", value: totalImpressionsOther, color: '#FFBB28' }
    ]

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default QrStatistics;