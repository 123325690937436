import React, { useEffect, useState } from "react";

const About = () => {

    const handleContactClick = () => {
        const mailtoLink = "mailto:letusconnecttechnologies@gmail.com?subject=Inquiry";
        window.location.href = mailtoLink;
    };

    // useEffect(() => {

    // }, []);

    return (
        <div className="container my-5">
            <div className="row align-items-center">
                <div className="col-md-6 mb-4">
                    <img
                        src="hotel-room-furniture.jpg"
                        alt="Elegant QR Code Stand"
                        className="img-fluid rounded shadow"
                    />
                </div>
                <div className="col-md-6">
                    <h1 className="display-4 mb-3">About Us</h1>
                    <p className="lead">
                        Welcome to our innovative advertising platform designed for short-term rental apartments and hotel rooms.
                        Our solution leverages stylish QR code stands, seamlessly blending functionality and aesthetics to deliver a
                        premium experience for guests and advertisers alike.
                    </p>
                    <p>
                        With our platform, guests can scan the elegantly placed QR codes to explore curated content and exclusive
                        offers, while advertisers gain access to a unique and highly targeted audience. It’s smart, efficient, and
                        effective advertising at its best.
                    </p>
                    <p>
                        Join us in revolutionizing in-room advertising and discover how our solution can enhance your business and
                        guest experience.
                    </p>
                    <button
                        onClick={handleContactClick}
                        className="btn btn-primary btn-lg"
                    >
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    )
}

export default About;